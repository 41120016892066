import React, { Component } from 'react';
import Navigation from "react-sticky-nav";
import GridLayout from 'react-grid-layout';
import './Home.css';
import Slider from "react-slick";
import RGL, { WidthProvider } from "react-grid-layout";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';

const ReactGridLayout = WidthProvider(RGL);

class Home extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.SLIDE_SIM = 1;
        this.SLIDE_VAULT = 2;
        this.SLIDE_HSM = 3;
        this.SLIDE_ANDROID = 4;
        this.SLIDE_SDR = 5;

        this.state = {};

        this.settings = {
            dots: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 8000,
            arrows: false
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.setSlide = this.setSlide.bind(this);
    }
    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    setSlide(index) {
        this.slider.slickGoTo(index);
    }

    componentDidMount() {
//        this.slider.slickPlay();
    }

    render() {

        var layout = [
            {i: 'tweets', x: 2, y: 0, w: 2, h: 4, static: true},
            {i: 'casestudies', x: 0, y: 0, w: 2, h: 4, static: true},
            {i: 'contactus', x: 4, y: 0, w: 2, h: 1, static: true},
            {i: 'copyright', x: 4, y: 5, w: 2, h: 1, static: true}
        ];

        return (
            <div className="home bwlinesbg">

                <div>
                <Navigation className="navbar">
                    <table width="100%">
                        <tbody>
                        <tr>
                            <td className="titlefont"><img src="/images/mode51softwarelogo2023-inverted-nomargin-406x55.png"/></td>
                            <td align="right">
                                <a href="https://github.com/mode51software" target="_blank"><img src="/images/github.png" width="50" hspace="10"/></a>
                                &nbsp; &nbsp;
                                <a href="https://medium.com/@mode51" target="_blank"><img src="/images/medium.png" width="50" hspace="10"/></a>
                            &nbsp; &nbsp;
                                <a href="https://twitter.com/mode51software" target="_blank"><img src="/images/twitter.png" width="50" hspace="10"/></a>
                            &nbsp; &nbsp;
                                <a href="https://www.linkedin.com/in/chris-newman-512a38/" target="_blank"><img src="/images/linkedin.png" width="50" hspace="10"/></a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Navigation>
                </div>

                <div className="carousel slidetext textfontfamily">
                    <Slider ref={slider => (this.slider = slider)} {...this.settings} className="textfontfamily">
                        <div className="slidefont-medium slidetext">
                            <table width="100%"><tr>
                                <td colSpan="2">
                                    <img src="/images/code.png" width="0" hspace="0px" vspace="20px" align="left" className="slidedevtitleoffset"/>
                                    <br/><span className="slidetitletext slidedevtitleoffset"><b>Mo</b>bile <b>De</b>velopment and Cybersecurity Software</span>
                                    <img src="/images/thales-bluesilver.png" width="200" align="right" style={{marginRight: 10}} valign="top"/>
                                    <br/>
                                    <table width="100%">
                                        <tr>
                                            <td width="70"></td>
                                            <td valign="top">
                                            <ul className="slidetext">
                                                <li><a href="#" className="slidelink" onClick={() => this.setSlide(this.SLIDE_SIM)}>SIM Provisioning including SGP.22 for eSIM</a></li>
                                                <li><a href="#" className="slidelink" onClick={() => this.setSlide(this.SLIDE_HSM)}>Hardware Security Module Integration</a></li>
                                                <li><a href="#" className="slidelink" onClick={() => this.setSlide(this.SLIDE_VAULT)}>HashiCorp Vault Plugins</a></li>
                                                <li><a href="#" className="slidelink" onClick={() => this.setSlide(this.SLIDE_ANDROID)}>Android including Magisk Modules</a></li>
                                                <li><a href="#" className="slidelink" onClick={() => this.setSlide(this.SLIDE_SDR)}>Software Defined Radio</a></li>
                                                <li>UK based</li>
                                            </ul>
                                            </td>
                                            <td>
                                                <table cellPadding={8} className="logostream" align="right">
                                                    <tr>
                                                        <td><img src="/images/logo_java.png" width="48"/></td>
                                                        <td><img src="/images/logo_go.png" width="120"/></td>
                                                        <td><img src="/images/logo_c.png" width="60"/></td>
                                                        <td><img src="/images/logo_tux.png" width="70"/></td>
                                                        <td><img src="/images/logo_docker.png" width="100"/></td>
                                                        <td><img src="/images/logo_android.png" width="80"/></td>
                                                        <td><img src="/images/logo_springboot2.png" width="140"/></td>
                                                        <td><img src="/images/logo_mysql.png" width="100"/></td>
                                                        <td><img src="/images/logo_cassandra.png" width="100"/></td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr></table>

                        </div>
                        <div className="slides slidesimprovisioning">
                            <table className="slidedevtitleoffset" width="100%"><tr>
                                <td>
                                    <br/><span className="slidetitletext">SIM Provisioning including eSIM RSP</span>
                                    <ul className="slidetextshadow">
                                        <li>EE UK needed to launch the <a className="linkwhite" href="https://en.wikipedia.org/wiki/Apple_SIM" target="_blank">Apple SIM in 2014</a></li>
                                        <li>Mode51 developed a lightweight SIM provisioning system integrated with a Nokia HSS via SPML and exporting SIM data to manufacturers</li>
                                        <br/>
                                        <li>Mode51 is now developing an <a className="linkwhite" href="https://www.gsma.com/esim/resources/sgp-22-v3-0/" target="_blank">SM-DP+</a> for eSIM Remote SIM Provisioning</li>
                                        <li><a className="linkwhite" href="https://medium.com/@mode51">Follow us on Medium</a> for technical eSIM articles
                                        including an <a className="linkwhite" href="https://medium.com/@mode51/esim-rsp-sm-dp-common-mutual-authentication-part-1-82a9f82c3d54" target="_blank">in-depth study of the Common Mutual Authentication procedure</a></li>
                                    </ul>
                                </td>
                                <td>
                                    <img src="/images/smdpp.png" width="450"/>
                                </td>
                            </tr>
                            </table>
                        </div>
                        <div className="slides slidevault">
                            <table className="slidedevtitleoffset" width="50%"><tr>
                                <td>
                                    <br/><span className="slidetitletextblack">HSM backed PKI Plugin for HashiCorp Vault</span>
                                    <ul className="slidetextshadowblack">
                                        <li>BT UK wanted to use Vault for PKI but required the use of HSMs<br/>&nbsp;</li>
                                        <li>Mode51 developed a new open source Go PKI plugin with support for Entrust's nShield and Thales's SafeNet.
                                            &nbsp; <a href="https://github.com/mode51software/vaultplugin-hsmpki" target="_blank">Check it out on GitHub</a><br/>&nbsp;</li>
                                        <li>Get in touch to discuss your custom Vault requirements</li>
                                    </ul>
                                </td>
                                <td>
                                </td>
                            </tr>
                            </table>
                        </div>
                        <div className="slides slidehsm">
                            <table className="slidedevtitleoffset" width="50%"><tr>
                                <td>
                                    <br/><span className="slidetitletext">HSMs for SIM Provisioning</span>
                                    <ul className="slidetext">
                                        <li>A Tier 1 Mobile Operator needed to retain the security of their SIM provisioning system's key management in a low entropy virtualised environment<br/>&nbsp;</li>
                                        <li>Mode51 provides custom firmware and a shared client library with easy to use APIs for seamless HSM integration of secured business logic and keys<br/>&nbsp;</li>
                                        <li>We have <a href="https://cpl.thalesgroup.com/encryption/hardware-security-modules/protectserver-hsms" className="slidelink" target="_blank">SafeNet ProtectServer</a>&nbsp;
                                            and <a href="https://cpl.thalesgroup.com/encryption/hardware-security-modules/network-hsms" className="slidelink" target="_blank">Luna</a> HSM expertise and we are working with Entrust's nShield</li>
                                    </ul>
                                </td>
                                <td>
                                </td>
                            </tr>
                            </table>
                        </div>
                        <div className="slides slidesigsite">
                            <table className="slidedevtitleoffset" width="100%"><tr>
                                <td width="40%">
                                    <span className="slidetitletextblack">Android AR</span>
                                    <ul className="slidetextshadowblack">
                                        <li>We developed floor51's SigSite Android Augmented Reality app and the backend processing<br/>&nbsp;</li>
                                        <li>Coverage data is overlaid on floorplans using the spatial positioning enabled by the S20's ToF camera</li>
                                    </ul>
                                    <img src="/images/sigsiteappcam.png" width="130" className="slidedevimageoffset" align="left"/>
                                    &nbsp; &nbsp;
                                    <a href="https://youtu.be/xRfzS_vosno" target="_blank">
                                        <img src="/images/sigsiteyoutube.png" width="130" className="slidedevimageoffset" align="left"/></a>
                                </td>
                                <td align="center">
                                    <br/>
                                </td>
                            </tr>
                            </table>
                        </div>
                        <div className="slides slidesdr">
                            <table className="slidedevtitleoffset" width="55%"><tr>
                                <td>
                                    <br/><span className="slidetitletext">SDR Scan R&D</span>
                                    <ul className="slidetextshadow">
                                        <li>Spectrum integrity depends on the identification of anomalous behaviour<br/>&nbsp;</li>
                                        <li>Mode51 used an anechoic chamber at BT's Adastral Park facility to demonstrate a
                                            system capable of recognizing the appearance of a rogue base station caught in
                                            the act of getting a phone to hop onto a fake 2G cell<br/><br/>
                                        </li>
                                        <li>We have <a href="https://openairinterface.org/" className="slidelink" target="_blank">OAI</a>,
                                            &nbsp;<a href="https://www.srslte.com/" className="slidelink" target="_blank">srsLTE</a>
                                            &nbsp;and <a href="https://yatebts.com/" className="slidelink" target="_blank">YateBTS</a> expertise</li>
                                    </ul>
                                </td>
                                <td>
\                               </td>
                            </tr>
                            </table>
                        </div>
                    </Slider>
                </div>

                <br/>
                <br/>
                <ReactGridLayout className="grid-layout" layout={layout}
                            cols={6} rowHeight={30} items={3}
                            isDraggable={false}
                >

                    <div key="tweets">

                        <table width="100%" className="panel-table">
                            <tr>
                                <td className="panel-title">
                                    Tweets
                                </td>
                            </tr>
                            <tr>
                                <td className="panel-body">

                                    <TwitterTimelineEmbed
                                        sourceType="profile"
                                        screenName="mode51software"
                                        options={{height: 300}}
                                        noHeader="true"
                                        noFooter="true"
                                        noBorders="true"
                                    />

                                </td>
                            </tr>
                        </table>
                    </div>

                    <div key="casestudies">

                        <table width="100%" className="panel-table">
                            <tr>
                                <td className="panel-title">
                                    Case Studies
                                </td>
                            </tr>
                            <tr>
                                <td className="panel-body">
                                    <ul>
                                        <li><a href="https://mode51.software/downloads/casestudies/UsingHSMs-MobileNetworks-Public-20210210.pdf">
                                            Summary Paper on Using HSMs for Compliance with Evolving Mobile Telecoms Security Regulation Feb 2021
                                        </a>
                                            <br/>&nbsp;
                                        </li>
                                        <li><a href="https://mode51.software/downloads/casestudies/SECURING_SIM_CREDENTIALS_GENERATION-20201110.pdf">
                                            Securing SIM Credentials Generation for a Tier 1 Mobile Operator using SafeNet HSMs
                                            </a>
                                            <br/>&nbsp;
                                        </li>
                                        <li><a href="https://mode51.software/downloads/casestudies/SECURING_HSSUDM_AUTHENTICATION-20201110.pdf">
                                            Securing HSS/UDM Authentication for a Tier 1 Vendor Using Hardware Security Modules
                                            </a>
                                            <br/>&nbsp;
                                        </li>
                                        <li><a href="https://mode51.software/downloads/casestudies/SDR_SIGNALSCANNING-20201201.pdf">
                                            SDR based signal scanning
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div key="contactus">

                        <table width="100%" className="panel-table">
                            <tr>
                                <td className="panel-title">
                                    Get in touch
                                </td>
                            </tr>
                            <tr>
                                <td className="panel-body textfontfamily">

                                    To find out how we can help with your projects
                                    <br/><br/>Email us: <span className="email">hello@mode51.software</span>
                                    &nbsp; <a href="https://mode51.software/downloads/files/chrisnewman-mode51-pub-20221110.asc" className="panel-link"><small>GPG key</small></a>
                                    <br/><br/><a href="https://www.linkedin.com/in/chris-newman-512a38/" className="linkblack" target="_blank">Connect on LinkedIn</a>

                                </td>
                            </tr>
                        </table>
                        { /*
                        <table width="100%" className="panel-table" border={0}>
                            <tr>
                                <td className="grid-celllogo">
                                    <img src="/images/thaleslogo1.png" width={40}/>
                                </td>
                                <td className="grid-celltitle">
                                    <header className="panel-header">SafeNet HSM Custom Firmware</header>
                                </td>
                                <td className="grid-cellbody">
                                    Custom firmware and client library integrations for Thales's SafeNet Hardware Security Modules
                                    <br/>We are a <a href="https://cpl.thalesgroup.com/partners/technology-partners">Thales Silver Partner</a>.
                                    <p>
                                        BT needed to retain the security of their SIM provisioning system's key management in a low entropy virtualised environment.
                                        Mode51 provides custom firmware and a shared client library with easy to use APIs for seamless HSM integration of secured business logic and keys
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className="grid-celllogo">
                                    <img src="/images/sdrscanthumb.png" width={50}/>
                                </td>
                                <td className="grid-cellsingleline">
                                    <header className="panel-header">SDR R&D</header>
                                </td>
                                <td className="grid-cellbody">
                                    Spectrum integrity depends on visibility and monitoring for anomalous behaviour.
                                    <p>
                                        mode51 used an anechoic chamber at BT's Adastral Park facility to demonstrate a
                                        system capable of recognizing the appearance of a rogue base station caught in
                                        the act of getting a phone to hop onto a fake 2G cell
                                    </p>
                                    Check out our <a href="/downloads/sdr/a1568295311/1568295331-3d.html?12">3D spectrum graph</a>
                                    and a 3D landscape visualisation in Unreal Engine
                                </td>
                            </tr>
                            <tr>
                                <td className="grid-celllogo">
                                    <img src="/images/indoorplusthumb.png" width={50}/>
                                </td>
                                <td className="grid-celltitle">
                                    <header className="panel-header">Android AR</header>
                                </td>
                                <td className="grid-cellbody">
                                    Indoor positioning using Augmented Reality in the absence of GPS enables coverage collection
                                    overlays on floorplans.
                                    <br/>Our technology has been used to power SignalScore by floor51
                                    <p>
                                    </p>
                                </td>
                            </tr>
                        </table>
                        */ }
                    </div>
                    <div key="copyright">

                        <table width="100%" className="panel-table">
                            <tr>
                                <td className="panel-title">
                                    About Us
                                </td>
                            </tr>
                            <tr>
                                <td className="panel-body textfontfamily">

                                    <a href="https://www.linkedin.com/in/chris-newman-512a38/" className="linkblack" target="_blank">Chris Newman, Software Development</a>
                                    <br/>
                                    <br/>A N Other, DevOps
                                    <br/>
                                    <br/><span className="copyright">&copy; Mode51 Software Ltd
                                    <br/>
                                    <br/>Company Number: 13007792</span>

                                </td>
                            </tr>
                        </table>
                    </div>
                </ReactGridLayout>

            </div>

    );

    }

}
export default Home;

